// extracted by mini-css-extract-plugin
export var designDevelopmentExpertsSection = "n_bs";
export var designDevelopmentServices = "n_bq";
export var designDifferenceBetweenSection = "n_br";
export var designFeaturedCaseSection = "n_bB";
export var designFullVideoSection = "n_bv";
export var designInsightsSection = "n_bC";
export var designPhaseSection = "n_bx";
export var designPrimeSection = "n_bp";
export var designProcessSection = "n_bw";
export var designProjLogoSection = "n_by";
export var designTestimonialPosSection = "n_bt";
export var domainExpertiseDesign = "n_bz";