import { getImage, StaticImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/design/Anchors'
import DesignDeliverables from '~/mock/services/design/DesignDeliverables'
import DesignFAQ from '~/mock/services/design/DesignFAQ'
import DesignIndustries from '~/mock/services/design/DesignIndustries'
import DesignProcess from '~/mock/services/design/DesignProcess'
import { ServicesDesignPageProps } from '~/pages/services/ui-ux-design'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import DevelopmentServicesSection from '~/views/Services/components/DevelopmentServicesSection'
import ServicesDifferenceBetween from '~/views/Services/components/ServicesDifferenceBetween'
import ServicesFullVideoSection from '~/views/Services/components/ServicesFullVideoSection'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesStepsProcess from '~/views/Services/components/ServicesStepsProcess'
import useDesignStaticQuery from '~/views/Services/Design/useDesignStaticQuery'

import * as containerStyles from './Design.module.scss'

const clientName = 'David Watkins'

const ServicesDesign = ({ data }: ServicesDesignPageProps) => {
  const query = useDesignStaticQuery()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const portfolioPMK = getImage(fileToImageLikeData(query.portfolioPMK))
  const portfolioMalleni = getImage(fileToImageLikeData(query.portfolioMalleni))
  const fullVideoImage = getImage(fileToImageLikeData(query.fullVideoImage))
  const quoteCodicaInessaPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaInessaPhoto),
  )

  return (
    <MainLayout scrollTop>
      <div className={containerStyles.designPrimeSection}>
        <PrimeSectionServices
          titleTop="UI/UX Design"
          title="Services"
          description="We help entrepreneurs quickly create a user-oriented desktop and mobile app design for all their business ideas and save time and budget."
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="UI/UX Design Services"
          breadcrumbCurrentPath="/services/ui-ux-design/"
        />
      </div>

      <StickyNavigation anchors={Anchors} />

      <section
        className={containerStyles.designDevelopmentServices}
        id="benefits"
      >
        <HeadSection
          position="center"
          title="Benefits you gain from design development with Codica’s UI/UX services"
          description="Improve your customers’ experience - raise the number of new clients and achieve your business goals."
          titleLarge
        />
        <LazyHydrate whenVisible>
          <DevelopmentServicesSection />
        </LazyHydrate>
      </section>

      <section className={containerStyles.designDifferenceBetweenSection}>
        <HeadSection position="center" title="Difference between UI and UX" />
        <ServicesDifferenceBetween
          titleFirst="What tasks do the user interface (UI) designers perform:"
          listFirst={[
            { item: 'Create the website style' },
            {
              item: 'Consider the state and appearance of all user interface elements and the font hierarchy',
            },
            {
              item: 'Develop a guideline to collect all the elements above for ease of development',
            },
          ]}
          titleSecond="What tasks do user experience (UX) designers perform"
          listSecond={[
            { item: 'Study the market features' },
            { item: 'Execute a portrait of the target audience' },
            { item: 'Conduct user research' },
            {
              item: 'Build a working high-fidelity prototype of the software',
            },
          ]}
        />
      </section>

      <section
        className={containerStyles.designDevelopmentExpertsSection}
        id="services"
      >
        <HeadSection
          position="center"
          title="Services by UI/UX design development experts"
          description="Our professional design team knows how to create an eye-catching and user-friendly website that your users will love."
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'Design is another essential phase of the software development life cycle.',
            },
            {
              text: `The custom design solution is more about recognition, involvement, and wow effect. It meets the brand's requirements as much as possible, as opposed to the templates. At Codica, our UX and UI designers have colossal experience designing custom software products.`,
            },
            {
              text: 'The client shares his vision of the product that he would like to see at the end of development, and we turn this vision into reality.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.designTestimonialPosSection}>
        <Testimonial
          image={quoteCodicaInessaPhoto}
          name="Inessa"
          position="UI/UX Lead at Codica"
          quote={[
            {
              text: '“Each project is a new challenge of how to make a beautiful and user-friendly interface. There is much work, analysis, and reflection behind the design screens in Figma. Research, logic creating, making prototypes, and visual style are the stages that we spend a lot of time on to get a good product.',
            },
            {
              text: 'We love what we do and improving our skills all the time.”',
            },
          ]}
        />
      </section>

      <CallToAction
        title="Do you need a user-friendly design for your project?"
        subtitle="We will help to bring it to life."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.designFullVideoSection} id="cases">
        <HeadSection
          position="center"
          title="Our case studies"
          description="We are always glad to share our successfully completed case study projects that can inspire you and give a vision of our UX/UI design services."
        />
        <LazyHydrate whenVisible>
          <ServicesFullVideoSection
            videoLink="https://www.youtube.com/embed/A8Bd1aiwpRU"
            videoImage={fullVideoImage}
            videoTitle="Our case studies"
          />
        </LazyHydrate>
      </section>

      <section className={containerStyles.designProcessSection} id="process">
        <HeadSection
          position="center"
          title="Steps of our UI/UX design process"
          description="Our iterative design development process ensures the team follows standard protocols to deliver projects consistently while meeting the company’s quality standards. Below we have listed the main UX/UI design development steps we follow at Codica."
          descriptionLarge
        />
        <ServicesStepsProcess dataContent={DesignProcess} />
      </section>

      <section className={containerStyles.designFeaturedCaseSection}>
        <HeadSection
          position="center"
          title="Featured marketplace case study"
        />
        <DiscoverWorksItem
          key={2}
          gradient="gradientMalleni"
          image={portfolioMalleni}
          header="Custom E-commerce Solution"
          title="The first online marketplace in Europe and the UK that allows users to buy and sell their services and products in one place."
          resultsLeft="Built a complex marketplace that includes B2C and C2C environments."
          resultsRight="Created an enhanced design for web solution parts to engage users."
          link="custom-multi-vendor-marketplace-platform"
          review={[
            '“We turned Codica with a request to build a complex marketplace from scratch. The Codica team made us feel involved every step of the way from the beginning and was focused on our needs.',
            <div key="featuredCase" style={{ paddingTop: 10 }} />,
            'As a result, we received a robust and easy-to-use web solution with all desired features.”',
          ]}
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/AvatarDraft.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName="CEO"
          clientPosition="E-commerce Marketplace Company, United Kingdom"
        />
      </section>

      <section className={containerStyles.designPhaseSection} id="deliverables">
        <HeadSection
          position="center"
          title="Deliverables you will get at the end of the design phase"
          description="The efforts of the UI/UX specialists can be translated into a list of deliverables of the custom web design services. Below are descriptions of artifacts that will help you understand what you will get as an outcome when you partner with the Codica team."
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={DesignDeliverables} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.designProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <CallToAction
        title="Ready to design your product with us?"
        subtitle="Tell us about your project"
        classNameForGA="servicesCTA_2"
        linkName="Get in touch"
        link="/contacts/"
      />

      <section
        className={containerStyles.domainExpertiseDesign}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Web design expertise for different domains you can rely on"
          description="Our experience in creating web solutions for particular industries has allowed us to grow our UI/UX expertise. We provide consultations on every stage of the project development and create products following the best web design practices."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={DesignIndustries} />
      </section>

      <section className={containerStyles.designFeaturedCaseSection}>
        <HeadSection
          position="center"
          title="Featured marketplace case study"
        />
        <DiscoverWorksItem
          key={3}
          gradient="gradientPmk"
          image={portfolioPMK}
          header="Online Service Marketplace"
          title="PlanMyKids is a service marketplace where parents can plan and book kids’ activities nearby. The platform offers a simple solution that connects parents with after-school programs and camps."
          resultsRight="Delivered a scalable MVP on time and below budget."
          resultsLeft="Ensured great user experience on both desktop and smartphone with a PWA."
          link="kids-activities-service-marketplace"
          review="“Working with the Codica team has been a great experience. All the value we thought they would provide during the selection process was met during the delivery. In the end Codica delivered our product MVP under budget, which for a bootstrapped company was important.”"
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/clients/client-pmk.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Founder of PlanMyKids"
        />
      </section>

      <section className={containerStyles.designInsightsSection} id="articles">
        <HeadSection
          position="left"
          title="Featured articles on UI/UX design"
        />
        <RecentArticles data={data} />
      </section>

      <section id="free-quote">
        <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={DesignFAQ} />
      </section>
    </MainLayout>
  )
}

export default ServicesDesign
